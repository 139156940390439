import moment from "moment";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFCheckBox from "../../../../RAFComponents/Inputs/RAFCheckBox";
import { Condition } from "../../../../RAFComponents/Inputs/RAFForm";
import {
  RAFFormContext,
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFIconImage from "../../../../RAFComponents/Navigation/RAFIconImage";
import RAFTabGroupBtn from "../../../../RAFComponents/Navigation/RAFTabGroupBtn";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  IsNotNullOrWhiteSpace,
  checkIsSearchTextMatch,
  convertUTCDateToLocalTimezone,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant } from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { EmployeeAvailableDisplayResponseRow } from "../../../CareESIO/Employee/EmployeeHelper/EmployeeHelper";
import UserProfilePhoto from "../../User/UserProfilePhoto";
import {
  AssigneeCardContentListItemRow,
  getAssigneeCardContentListItems,
} from "../ServiceTransactionHelper";
import { ServiceTransactionRow } from "../ServiceTransactionRow";

const getStartDateEndDateDisplayValue = (startDate: Date, endDate: Date) => {
  if (isNotNullAndUndefined(startDate) && isNotNullAndUndefined(endDate)) {
    const startedDate = convertUTCDateToLocalTimezone(startDate);
    const endedDate = convertUTCDateToLocalTimezone(endDate);
    const startTime = moment(startedDate).format("hh:mm A");
    const endTime = moment(endedDate).format("hh:mm A");

    return `${startTime} - ${endTime}`;
  } else {
    return "Not set";
  }
};

interface IProps {
  isDisabled: boolean;
}

interface IState {
  newSearchedText: string;
  assigneeCardContentListItemRow: AssigneeCardContentListItemRow;
}

function ServiceTransactionSelectAssignee({
  isDisabled,
}: PropsWithChildren<IProps>) {
  const outerDivId = `raf_dlg_Outer_Div_${RAFEntityName.ServiceTransaction}_select_assignee`;

  const inputText = React.useRef<HTMLInputElement>(null);
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      newSearchedText: null,
      assigneeCardContentListItemRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const showEmployeeCareTeamOnlyAvailable: boolean = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeamOnlyAvailable")
    );
    const clientUID: string = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("RelatedToUID")
    );
    const startDate: Date = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("StartDate")
    );
    const endDate: Date = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("EndDate")
    );
    const showEmployeeCareTeam: string = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeam")
    );
    const assigneeCardContentListItemRow: AssigneeCardContentListItemRow =
      await getAssigneeCardContentListItems(
        showEmployeeCareTeam,
        showEmployeeCareTeamOnlyAvailable,
        clientUID,
        startDate,
        endDate
      );

    hideProgress(progressDiv);
    setState({ assigneeCardContentListItemRow });
  };

  const onChangeCheckBoxValue = (
    isChecked: boolean,
    selectedItemUID: string,
    selectedItem: string
  ) => {
    if (isChecked) {
      setFormValue(
        rafFormContextValue,
        propertyOf<ServiceTransactionRow>("AssigneeUID"),
        selectedItemUID
      );
      setFormValue(
        rafFormContextValue,
        propertyOf<ServiceTransactionRow>("Assignee"),
        selectedItem
      );
    } else {
      setFormValue(
        rafFormContextValue,
        propertyOf<ServiceTransactionRow>("AssigneeUID"),
        null
      );
      setFormValue(
        rafFormContextValue,
        propertyOf<ServiceTransactionRow>("Assignee"),
        null
      );
    }
  };

  const onChangeEmployeeCareTeamOnlyAvailable = async (
    showEmployeeCareTeamOnlyAvailable: boolean
  ) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const showEmployeeCareTeam: string = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeam")
    );
    const clientUID: string = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("RelatedToUID")
    );
    const startDate: Date = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("StartDate")
    );
    const endDate: Date = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("EndDate")
    );
    const assigneeCardContentListItemRow: AssigneeCardContentListItemRow =
      await getAssigneeCardContentListItems(
        showEmployeeCareTeam,
        showEmployeeCareTeamOnlyAvailable,
        clientUID,
        startDate,
        endDate
      );

    hideProgress(progressDiv);
    setState({ assigneeCardContentListItemRow });
  };

  const getAvailableEmployeeCareTeamContent = (isDisabled: boolean) => {
    const { assigneeCardContentListItemRow } = state;
    const careTeamAvailability = isNotNullAndUndefined(
      assigneeCardContentListItemRow
    )
      ? assigneeCardContentListItemRow.careTeamAvailability
      : null;

    return (
      <>
        <Condition
          when={propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeam")}
          is={CareEsioEntity.Employee.EntityName}
        >
          {getAvailableEmployeeLookupContent(isDisabled)}
        </Condition>
        <Condition
          when={propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeam")}
          is={CareEsioEntity.CareTeam.EntityName}
        >
          {getAvailableCareTeamLookupContent(
            careTeamAvailability,
            true,
            isDisabled
          )}
        </Condition>
      </>
    );
  };

  const getEmployeeCareTeamContent = (isDisabled: boolean) => {
    const { assigneeCardContentListItemRow } = state;
    const allCareTeamList = isNotNullAndUndefined(
      assigneeCardContentListItemRow
    )
      ? assigneeCardContentListItemRow.allCareTeamList
      : null;

    return (
      <>
        <Condition
          when={propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeam")}
          is={CareEsioEntity.Employee.EntityName}
        >
          {getEmployeeLookupContent(isDisabled)}
        </Condition>
        <Condition
          when={propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeam")}
          is={CareEsioEntity.CareTeam.EntityName}
        >
          {getAvailableCareTeamLookupContent(
            allCareTeamList,
            false,
            isDisabled
          )}
        </Condition>
      </>
    );
  };

  const getAvailableCareTeamLookupContent = (
    careTeamAvailability: EmployeeAvailableDisplayResponseRow[],
    isAvailable: boolean,
    isDisabled: boolean
  ) => {
    return (
      <div className="col-12">
        <Field name={propertyOf<ServiceTransactionRow>("AssigneeUID")}>
          {({ input }) => {
            const assigneeUID = input.value;
            return (
              // <CustomCardWidget title={isAvailable ? "Available Care Team Member(s)" : "Care Team Member(s)"} cardClassName="surface_neutral_base" cardHeaderClassName="subtitle_1 semi_bold">
              isNotEmptyArray(careTeamAvailability) ? (
                <div className="row gx-0 gy-3">
                  {careTeamAvailability.map(
                    (
                      careTeamAvailabilityRow: EmployeeAvailableDisplayResponseRow
                    ) => {
                      const isChecked =
                        careTeamAvailabilityRow.EmployeeUID === assigneeUID;
                      const name = IsNotNullOrWhiteSpace(
                        careTeamAvailabilityRow.EmployeeName
                      )
                        ? careTeamAvailabilityRow.EmployeeName
                        : "Not set";

                      const show = isChecked
                        ? true
                        : IsNotNullOrWhiteSpace(state.newSearchedText)
                        ? checkIsSearchTextMatch(state.newSearchedText, name)
                        : true;
                      if (!show) return null;

                      return (
                        <div
                          className="col-12"
                          key={careTeamAvailabilityRow.EmployeeUID}
                        >
                          <div
                            className="pointer"
                            onClick={() => {
                              if (!isDisabled) {
                                onChangeCheckBoxValue(
                                  !isChecked,
                                  careTeamAvailabilityRow.EmployeeUID,
                                  careTeamAvailabilityRow.EmployeeName
                                );
                              }
                            }}
                          >
                            {/* <CustomCardWidget
                                                        onClick={() => {
                                                            if (!isDisabled) {
                                                                onChangeCheckBoxValue(
                                                                    !isChecked,
                                                                    careTeamAvailabilityRow.EmployeeUID,
                                                                    careTeamAvailabilityRow.EmployeeName
                                                                );
                                                            }
                                                        }}
                                                        cardClickable
                                                        cardClassName={`${isChecked ? "custom__card__active " : ""
                                                            } hover-parent-div`}
                                                        cardContentClassName="p-2-5"
                                                    > */}
                            <div className="row gx-2 align-items-center">
                              {/* {BrowserIsDevice ? (
                                                                <div
                                                                    className="col-auto"
                                                                    id={`employee_${careTeamAvailabilityRow.EmployeeUID}`}
                                                                >
                                                                    {!isChecked ? (
                                                                        <div>
                                                                            <UserProfilePhoto
                                                                                uid={
                                                                                    careTeamAvailabilityRow.EmployeeUID
                                                                                }
                                                                                relatedToType={
                                                                                    CareEsioEntity.Employee.EntityName
                                                                                }
                                                                                canEdit={false}
                                                                                profileImgCss={
                                                                                    "profileImg-container-normal"
                                                                                }
                                                                                progressDivID={`#employee_${careTeamAvailabilityRow.EmployeeUID}`}
                                                                                title={name}
                                                                                iconSize="24"
                                                                                fontSize="10"
                                                                                emptyAvatarType="Initial"
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <RAFIconImage
                                                                                iconCssClass="fal fa-check"
                                                                                moduleavatar={`raf-avatar raf-sm circle ${!isChecked
                                                                                    ? "raf-avatar-grey"
                                                                                    : "raf-avatar-primary"
                                                                                    }`}
                                                                            ></RAFIconImage>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="col-auto"
                                                                    id={`employee_${careTeamAvailabilityRow.EmployeeUID}`}
                                                                >
                                                                    {!isChecked && (
                                                                        <div className="hover-hide-child-div">
                                                                            <UserProfilePhoto
                                                                                uid={
                                                                                    careTeamAvailabilityRow.EmployeeUID
                                                                                }
                                                                                relatedToType={
                                                                                    CareEsioEntity.Employee.EntityName
                                                                                }
                                                                                canEdit={false}
                                                                                profileImgCss={
                                                                                    "profileImg-container-normal"
                                                                                }
                                                                                progressDivID={`#employee_${careTeamAvailabilityRow.EmployeeUID}`}
                                                                                title={name}
                                                                                iconSize="24"
                                                                                fontSize="10"
                                                                                emptyAvatarType="Initial"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    <div
                                                                        className={
                                                                            !isChecked ? "hover-child-div" : ""
                                                                        }
                                                                    >
                                                                        <RAFIconImage
                                                                            iconCssClass="fal fa-check"
                                                                            moduleavatar={`raf-avatar raf-sm circle ${!isChecked
                                                                                ? "raf-avatar-grey"
                                                                                : "raf-avatar-primary"
                                                                                }`}
                                                                        ></RAFIconImage>
                                                                    </div>
                                                                </div>
                                                            )} */}
                              <div className="col-auto">
                                <UserProfilePhoto
                                  uid={careTeamAvailabilityRow.EmployeeUID}
                                  relatedToType={
                                    CareEsioEntity.Employee.EntityName
                                  }
                                  canEdit={false}
                                  profileImgCss={
                                    "profileImg-container-normal-sm"
                                  }
                                  progressDivID={`#employee_${careTeamAvailabilityRow.EmployeeUID}`}
                                  title={name}
                                  iconSize="32"
                                  fontSize="12"
                                  emptyAvatarType="Initial"
                                />
                              </div>
                              <div className="col">
                                {isAvailable === true &&
                                isNotEmptyArray(
                                  careTeamAvailabilityRow.EmployeeInfo
                                ) ? (
                                  <div className="col align-items-center">
                                    <span className="body_1 medium">
                                      {name}
                                    </span>
                                    <div>
                                      <table>
                                        <tbody>
                                          {careTeamAvailabilityRow.EmployeeInfo.map(
                                            (employeeInfo) => {
                                              return (
                                                <tr>
                                                  <td>
                                                    <span>
                                                      {
                                                        employeeInfo.AvailabilityType
                                                      }
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <span>
                                                      {getStartDateEndDateDisplayValue(
                                                        employeeInfo.StartDate,
                                                        employeeInfo.EndDate
                                                      )}
                                                    </span>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="body_1 medium">
                                    <span>{name}</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-auto">
                                <RAFIconImage
                                  iconCssClass={
                                    !isChecked ? "" : "fal fa-check"
                                  }
                                  moduleavatar={`raf-avatar raf-sm circle ${
                                    !isChecked
                                      ? "raf-avatar-grey"
                                      : "raf-avatar-primary"
                                  }`}
                                ></RAFIconImage>
                              </div>
                            </div>
                            {/* </CustomCardWidget> */}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <div className="container-fluid px-0">
                  <RAFDeletedRecordState
                    title={
                      isAvailable
                        ? "No care team member available during this period"
                        : "No care team members for this client"
                    }
                  />
                </div>
              )
              // </CustomCardWidget>
            );
          }}
        </Field>
      </div>
    );
  };

  const getAvailableEmployeeLookupContent = (isDisabled: boolean) => {
    const { assigneeCardContentListItemRow } = state;
    const employeeAvailability = isNotNullAndUndefined(
      assigneeCardContentListItemRow
    )
      ? assigneeCardContentListItemRow.employeeAvailability
      : null;

    return (
      <div className="col-12">
        <Field name={propertyOf<ServiceTransactionRow>("AssigneeUID")}>
          {({ input }) => {
            const assigneeUID = input.value;
            return (
              // <CustomCardWidget
              //   title={`Available Employee(s)`}
              //   cardClassName="surface_neutral_base"
              //   cardHeaderClassName="subtitle_1 semi_bold fixed-height">
              <div>
                {isNotEmptyArray(employeeAvailability) ? (
                  <div className="row gx-0 gy-2">
                    {employeeAvailability.map(
                      (
                        employeeAvailabilityRow: EmployeeAvailableDisplayResponseRow
                      ) => {
                        const isChecked =
                          employeeAvailabilityRow.EmployeeUID === assigneeUID;
                        const name = IsNotNullOrWhiteSpace(
                          employeeAvailabilityRow.EmployeeName
                        )
                          ? employeeAvailabilityRow.EmployeeName
                          : "Not set";

                        const show = isChecked
                          ? true
                          : IsNotNullOrWhiteSpace(state.newSearchedText)
                          ? checkIsSearchTextMatch(state.newSearchedText, name)
                          : true;

                        if (!show) return null;

                        return (
                          <div
                            className="col-12"
                            key={employeeAvailabilityRow.EmployeeUID}
                          >
                            <div
                              className="pointer"
                              onClick={() => {
                                if (!isDisabled) {
                                  onChangeCheckBoxValue(
                                    !isChecked,
                                    employeeAvailabilityRow.EmployeeUID,
                                    employeeAvailabilityRow.EmployeeName
                                  );
                                }
                              }}
                            >
                              {/* <CustomCardWidget
                                                            onClick={() => {
                                                                if (!isDisabled) {
                                                                    onChangeCheckBoxValue(
                                                                        !isChecked,
                                                                        employeeAvailabilityRow.EmployeeUID,
                                                                        employeeAvailabilityRow.EmployeeName
                                                                    );
                                                                }
                                                            }}
                                                            cardClickable
                                                            cardClassName={`${isChecked ? "custom__card__active " : ""
                                                                } hover-parent-div`}
                                                            cardContentClassName="p-2-5"
                                                        > */}
                              <div className="row gx-2 align-items-center">
                                {/* {BrowserIsDevice ? (
                                                                    <div
                                                                        className="col-auto"
                                                                        id={`employee_${employeeAvailabilityRow.EmployeeUID}`}
                                                                    >
                                                                        {!isChecked ? (
                                                                            <div>
                                                                                <UserProfilePhoto
                                                                                    uid={
                                                                                        employeeAvailabilityRow.EmployeeUID
                                                                                    }
                                                                                    relatedToType={
                                                                                        CareEsioEntity.Employee.EntityName
                                                                                    }
                                                                                    canEdit={false}
                                                                                    profileImgCss={
                                                                                        "profileImg-container-normal"
                                                                                    }
                                                                                    progressDivID={`#employee_${employeeAvailabilityRow.EmployeeUID}`}
                                                                                    title={name}
                                                                                    iconSize="24"
                                                                                    fontSize="10"
                                                                                    emptyAvatarType="Initial"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <RAFIconImage
                                                                                    iconCssClass="fal fa-check"
                                                                                    moduleavatar={`raf-avatar raf-sm circle ${!isChecked
                                                                                        ? "raf-avatar-grey"
                                                                                        : "raf-avatar-primary"
                                                                                        }`}
                                                                                ></RAFIconImage>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="col-auto"
                                                                        id={`employee_${employeeAvailabilityRow.EmployeeUID}`}
                                                                    >
                                                                        {!isChecked && (
                                                                            <div className="hover-hide-child-div">
                                                                                <UserProfilePhoto
                                                                                    uid={
                                                                                        employeeAvailabilityRow.EmployeeUID
                                                                                    }
                                                                                    relatedToType={
                                                                                        CareEsioEntity.Employee.EntityName
                                                                                    }
                                                                                    canEdit={false}
                                                                                    profileImgCss={
                                                                                        "profileImg-container-normal"
                                                                                    }
                                                                                    progressDivID={`#employee_${employeeAvailabilityRow.EmployeeUID}`}
                                                                                    title={name}
                                                                                    iconSize="24"
                                                                                    fontSize="10"
                                                                                    emptyAvatarType="Initial"
                                                                                />
                                                                            </div>
                                                                        )}

                                                                        <div
                                                                            className={
                                                                                !isChecked ? "hover-child-div" : ""
                                                                            }
                                                                        >
                                                                            <RAFIconImage
                                                                                iconCssClass="fal fa-check"
                                                                                moduleavatar={`raf-avatar raf-sm circle ${!isChecked
                                                                                    ? "raf-avatar-grey"
                                                                                    : "raf-avatar-primary"
                                                                                    }`}
                                                                            ></RAFIconImage>
                                                                        </div>
                                                                    </div>
                                                                )} */}
                                <div className="col-auto">
                                  <UserProfilePhoto
                                    uid={employeeAvailabilityRow.EmployeeUID}
                                    relatedToType={
                                      CareEsioEntity.Employee.EntityName
                                    }
                                    canEdit={false}
                                    profileImgCss={
                                      "profileImg-container-normal-sm"
                                    }
                                    progressDivID={`#employee_${employeeAvailabilityRow.EmployeeUID}`}
                                    title={name}
                                    iconSize="32"
                                    fontSize="12"
                                    emptyAvatarType="Initial"
                                  />
                                </div>
                                <div className="col align-items-center">
                                  <span className="body_1 medium">{name}</span>
                                  <div>
                                    <table>
                                      <tbody>
                                        {employeeAvailabilityRow.EmployeeInfo.map(
                                          (employeeInfo) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <span>
                                                    {
                                                      employeeInfo.AvailabilityType
                                                    }
                                                  </span>
                                                </td>
                                                <td>
                                                  <span>
                                                    {getStartDateEndDateDisplayValue(
                                                      employeeInfo.StartDate,
                                                      employeeInfo.EndDate
                                                    )}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <RAFIconImage
                                    iconCssClass={
                                      !isChecked ? "" : "fal fa-check"
                                    }
                                    moduleavatar={`raf-avatar raf-sm circle ${
                                      !isChecked
                                        ? "raf-avatar-grey"
                                        : "raf-avatar-primary"
                                    }`}
                                  ></RAFIconImage>
                                </div>
                              </div>
                              {/* </CustomCardWidget> */}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div className="container-fluid px-0">
                    <RAFDeletedRecordState title="No employees available during this period" />
                  </div>
                )}
              </div>
              // </CustomCardWidget>
            );
          }}
        </Field>
      </div>
    );
  };

  const getEmployeeLookupContent = (isDisabled: boolean) => {
    const { assigneeCardContentListItemRow } = state;
    const allEmployeeLookUpList = isNotNullAndUndefined(
      assigneeCardContentListItemRow
    )
      ? assigneeCardContentListItemRow.allEmployeeLookUpList
      : null;
    const allEmployeeLookUpListLength = isNotEmptyArray(allEmployeeLookUpList)
      ? allEmployeeLookUpList.length
      : 0;

    return (
      <div className="col-12">
        <Field name={propertyOf<ServiceTransactionRow>("AssigneeUID")}>
          {({ input }) => {
            const assigneeUID = input.value;
            return (
              // <CustomCardWidget
              //   title={`All Employee (${allEmployeeLookUpListLength})`}
              //   cardClassName="surface_neutral_base"
              //   cardHeaderClassName="subtitle_1 semi_bold"
              // >
              <div>
                {isNotEmptyArray(allEmployeeLookUpList) ? (
                  <div className="row gx-0 gy-3">
                    {allEmployeeLookUpList.map(
                      (employeeLookUpRow: LookUpRow) => {
                        const isChecked = employeeLookUpRow.UID === assigneeUID;
                        const name = IsNotNullOrWhiteSpace(
                          employeeLookUpRow.Value
                        )
                          ? employeeLookUpRow.Value
                          : "Not set";

                        const show = isChecked
                          ? true
                          : IsNotNullOrWhiteSpace(state.newSearchedText)
                          ? checkIsSearchTextMatch(state.newSearchedText, name)
                          : true;

                        if (!show) return null;

                        return (
                          <div className="col-12" key={employeeLookUpRow.UID}>
                            <div
                              className="pointer"
                              onClick={() => {
                                if (!isDisabled) {
                                  onChangeCheckBoxValue(
                                    !isChecked,
                                    employeeLookUpRow.UID,
                                    employeeLookUpRow.Value
                                  );
                                }
                              }}
                            >
                              {/* <CustomCardWidget
                                                            onClick={() => {
                                                                if (!isDisabled) {
                                                                    onChangeCheckBoxValue(
                                                                        !isChecked,
                                                                        employeeLookUpRow.UID,
                                                                        employeeLookUpRow.Value
                                                                    );
                                                                }
                                                            }}
                                                            cardClickable
                                                            cardClassName={`${isChecked ? "custom__card__active " : ""
                                                                } hover-parent-div`}
                                                            cardContentClassName="p-2-5"
                                                        > */}
                              <div className="row gx-2 align-items-center">
                                {/* {BrowserIsDevice ? (
                                                                    <div
                                                                        className="col-auto"
                                                                        id={`employee_${employeeLookUpRow.UID}`}
                                                                    >
                                                                        {!isChecked ? (
                                                                            <div>
                                                                                <UserProfilePhoto
                                                                                    uid={employeeLookUpRow.UID}
                                                                                    relatedToType={
                                                                                        CareEsioEntity.Employee.EntityName
                                                                                    }
                                                                                    canEdit={false}
                                                                                    profileImgCss={
                                                                                        "profileImg-container-normal"
                                                                                    }
                                                                                    progressDivID={`#employee_${employeeLookUpRow.UID}`}
                                                                                    title={name}
                                                                                    iconSize="24"
                                                                                    fontSize="10"
                                                                                    emptyAvatarType="Initial"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <RAFIconImage
                                                                                    iconCssClass="fal fa-check"
                                                                                    moduleavatar={`raf-avatar raf-sm circle ${!isChecked
                                                                                        ? "raf-avatar-grey"
                                                                                        : "raf-avatar-primary"
                                                                                        }`}
                                                                                ></RAFIconImage>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="col-auto"
                                                                        id={`employee_${employeeLookUpRow.UID}`}
                                                                    >
                                                                        {!isChecked && (
                                                                            <div className="hover-hide-child-div">
                                                                                <UserProfilePhoto
                                                                                    uid={employeeLookUpRow.UID}
                                                                                    relatedToType={
                                                                                        CareEsioEntity.Employee.EntityName
                                                                                    }
                                                                                    canEdit={false}
                                                                                    profileImgCss={
                                                                                        "profileImg-container-normal"
                                                                                    }
                                                                                    progressDivID={`#employee_${employeeLookUpRow.UID}`}
                                                                                    title={name}
                                                                                    iconSize="24"
                                                                                    fontSize="10"
                                                                                    emptyAvatarType="Initial"
                                                                                />
                                                                            </div>
                                                                        )}

                                                                        <div
                                                                            className={
                                                                                !isChecked ? "hover-child-div" : ""
                                                                            }
                                                                        >
                                                                            <RAFIconImage
                                                                                iconCssClass="fal fa-check"
                                                                                moduleavatar={`raf-avatar raf-sm circle ${!isChecked
                                                                                    ? "raf-avatar-grey"
                                                                                    : "raf-avatar-primary"
                                                                                    }`}
                                                                            ></RAFIconImage>
                                                                        </div>
                                                                    </div>
                                                                )} */}
                                <div className="col-auto">
                                  <UserProfilePhoto
                                    uid={employeeLookUpRow.UID}
                                    relatedToType={
                                      CareEsioEntity.Employee.EntityName
                                    }
                                    canEdit={false}
                                    profileImgCss={
                                      "profileImg-container-normal-sm"
                                    }
                                    progressDivID={`#employee_${employeeLookUpRow.UID}`}
                                    title={name}
                                    iconSize="32"
                                    fontSize="12"
                                    emptyAvatarType="Initial"
                                  />
                                </div>
                                <div className="col d-flex align-items-center">
                                  <span className="body_1 medium">
                                    {employeeLookUpRow.Value}
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <RAFIconImage
                                    iconCssClass={
                                      !isChecked ? "" : "fal fa-check"
                                    }
                                    moduleavatar={`raf-avatar raf-sm circle ${
                                      !isChecked
                                        ? "raf-avatar-grey"
                                        : "raf-avatar-primary"
                                    }`}
                                  ></RAFIconImage>
                                </div>
                              </div>
                              {/* </CustomCardWidget> */}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div className="container-fluid px-0">
                    <RAFDeletedRecordState title="No employees" />
                  </div>
                )}
              </div>
              // </CustomCardWidget>
            );
          }}
        </Field>
      </div>
    );
  };

  const onChangeEmployeeCareTeam = async (
    item,
    showEmployeeCareTeam: string
  ) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    setFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeam"),
      showEmployeeCareTeam
    );
    const showEmployeeCareTeamOnlyAvailable: boolean = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeamOnlyAvailable")
    );
    const clientUID: string = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("RelatedToUID")
    );
    const startDate: Date = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("StartDate")
    );
    const endDate: Date = getFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("EndDate")
    );
    const assigneeCardContentListItemRow: AssigneeCardContentListItemRow =
      await getAssigneeCardContentListItems(
        showEmployeeCareTeam,
        showEmployeeCareTeamOnlyAvailable,
        clientUID,
        startDate,
        endDate
      );

    hideProgress(progressDiv);
    setState({ assigneeCardContentListItemRow });
  };

  const onClickClearAssignee = () => {
    setFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("AssigneeUID"),
      null
    );
    setFormValue(
      rafFormContextValue,
      propertyOf<ServiceTransactionRow>("Assignee"),
      null
    );
  };

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNotNullAndUndefined(e) && isNotNullAndUndefined(e.target)) {
      const newSearchedText = e.target.value;
      setState({
        newSearchedText: IsNotNullOrWhiteSpace(newSearchedText)
          ? newSearchedText
          : null,
      });
    }
  };

  const onClickClearButton = () => {
    if (
      isNotNullAndUndefined(inputText) &&
      isNotNullAndUndefined(inputText.current)
    ) {
      const inputVal = inputText.current.value;
      if (isNotNullAndUndefined(inputVal)) {
        inputText.current.value = null;
        setState({ newSearchedText: null });
      }
    }
  };

  const searchTextContent = () => {
    return (
      <div className="col-12 pb-2">
        <div className="row gx-0 gy-2">
          <div className="col-12">
            <div className="row gx-2">
              <div className="col align-items-center">
                <div className={`search-input-box-container list_search w-100`}>
                  <i
                    className={`${RAFButtonConstant.Search.IconCss} pe-2 searchIcon`}
                  ></i>
                  <input
                    ref={inputText}
                    type="text"
                    className="search-input-box"
                    placeholder="Search by keyword"
                    onChange={onChangeInputValue}
                  ></input>
                  <i
                    className={`${RAFButtonConstant.Clear.IconCss} px-2 clearIcon`}
                    onClick={onClickClearButton}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id={outerDivId}>
      <Field name={propertyOf<ServiceTransactionRow>("AssigneeUID")}>
        {({ input }) => {
          const assigneeUID = IsNotNullOrWhiteSpace(input.value)
            ? input.value
            : null;
          return (
            <Field name={propertyOf<ServiceTransactionRow>("Assignee")}>
              {({ input }) => {
                const assignee = IsNotNullOrWhiteSpace(input.value)
                  ? input.value
                  : null;
                return (
                  <CustomCardWidget
                    cardContentClassName="p-2"
                    cardClassName="surface_neutral_base border-0"
                  >
                    <div className="row gx-2 align-items-center">
                      <div className="col-auto">
                        <span className="body_2_light">Assignee:</span>
                      </div>
                      <div className="col">
                        <div className="row gx-1 align-items-center">
                          <div className="col-auto">
                            <UserProfilePhoto
                              uid={assigneeUID}
                              relatedToType={CareEsioEntity.Employee.EntityName}
                              canEdit={false}
                              profileImgCss={"profileImg-container-normal-sm"}
                              progressDivID={`#employee_${assigneeUID}`}
                              title={assignee ?? "Not Selected"}
                              iconSize="32"
                              fontSize="12"
                              emptyAvatarType={
                                IsNotNullOrWhiteSpace(assignee)
                                  ? "Initial"
                                  : "Icon"
                              }
                              emptyIconCss={"fas fa-user fa-sm"}
                            />
                          </div>
                          <div className="col">
                            <span className="subtitle_1 semi_bold">
                              {assignee ?? "Not Selected"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        {IsNotNullOrWhiteSpace(assignee) && (
                          <RAFButtonComponent
                            action={RAFButtonConstant.Clear}
                            onClick={onClickClearAssignee}
                            disabled={isDisabled}
                            className="custom-button-sm btn_brand_secondary transparent"
                            iconBtn
                          />
                        )}
                      </div>
                    </div>
                  </CustomCardWidget>
                  // <div className="row gy-0 gx-2 align-items-center">
                  //     <div className="col-12 d-flex align-items-center">
                  //         <div className="details-label me-1">Assignee</div>
                  //         <div className="group_btn_separator me-1"></div>
                  //         {IsNotNullOrWhiteSpace(assignee) && (
                  //             <RAFButtonComponent
                  //                 btnContent="Clear"
                  //                 action={RAFButtonConstant.Clear}
                  //                 showIcon={false}
                  //                 onClick={onClickClearAssignee}
                  //                 disabled={isDisabled}
                  //                 className="custom-button-sm btn_brand_secondary transparent"
                  //             />
                  //         )}
                  //     </div>
                  //     <div className="col">
                  //         <span className="details-value">
                  //             {assignee ?? "Not Selected"}
                  //         </span>
                  //     </div>
                  // </div>
                );
              }}
            </Field>
          );
        }}
      </Field>
      <div className="mt-2">
        <RAFCheckBox
          field={propertyOf<ServiceTransactionRow>("Notify")}
          label="Notify the worker"
          showLabel={true}
          uitype={"lightCheckbox"}
          labelPosition="right"
          inputFieldClassName="col-auto"
          labelClassName="col"
          formGroupClassName="mb-0"
          rowClassName="row g-2 align-items-center"
          colorStyle="secondary"
          componentCssClass="e-checkbox-md"
        />
      </div>

      <div className="mt-4">
        <div className="body_1 semi_bold">Select Assignee</div>
      </div>

      {/* <CustomCardWidget
                    headerTemplate={
                        <div className="custom__card__header fixed-height">
                            <div className="w-100">
                                <div className="row g-2 flex-wrap justify-content-between">
                                    <div className="col-auto">
                                        <RAFDropdownCC
                                            field={propertyOf<ServiceTransactionRow>(
                                                "ShowEmployeeCareTeam"
                                            )}
                                            label="Show"
                                            onChanged={onChangeEmployeeCareTeam}
                                            showLabel={false}
                                            disabled={isDisabled}
                                            componentCssClass="remove_color e-input-sm"
                                            inputFieldClassName="col-auto"
                                            showClearButton={false}
                                            formGroupClassName=""
                                        >
                                            <RAFChoiceOption
                                                label="Employee"
                                                value={CareEsioEntity.Employee.EntityName}
                                            />
                                            <RAFChoiceOption
                                                label="Care Team"
                                                value={CareEsioEntity.CareTeam.EntityName}
                                            />
                                        </RAFDropdownCC>
                                    </div>
                                    <div className="col-auto">

                                        <RAFCheckBox
                                            field={propertyOf<ServiceTransactionRow>(
                                                "ShowEmployeeCareTeamOnlyAvailable"
                                            )}
                                            label="Only available"
                                            labelClassName="col"
                                            inputFieldClassName="col-auto"
                                            labelPosition="right"
                                            rowClassName="row gx-2 align-items-center"
                                            formGroupClassName="mb-0"
                                            onChanged={onChangeEmployeeCareTeamOnlyAvailable}
                                            disabled={isDisabled}
                                            uitype="switch"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                > */}
      <div className="mt-2">
        <div className="row g-2">
          <div className="col-md">{searchTextContent()}</div>
          <div className="col-md-auto">
            <Field
              name={propertyOf<ServiceTransactionRow>("ShowEmployeeCareTeam")}
            >
              {({ input }) => {
                const ShowEmployeeCareTeam = IsNotNullOrWhiteSpace(input.value)
                  ? input.value
                  : null;

                const items = [
                  {
                    title: CareEsioEntity.Employee.DisplayName,
                    id: CareEsioEntity.Employee.EntityName,
                  },
                  {
                    title: "Care Team",
                    id: CareEsioEntity.CareTeam.EntityName,
                  },
                ];
                return (
                  <RAFTabGroupBtn
                    items={items}
                    selectedItem={ShowEmployeeCareTeam}
                    onSelect={(item) => onChangeEmployeeCareTeam(item, item.id)}
                    mode="TextOnly"
                    buttonStyle="secondary"
                  ></RAFTabGroupBtn>
                );
              }}
            </Field>
          </div>
          {/* <div className="col-12">
                        <CustomCardWidget>
                        <RAFCheckBox
                            field={propertyOf<ServiceTransactionRow>(
                                "ShowEmployeeCareTeamOnlyAvailable"
                            )}
                            label="Only available"
                            labelClassName="col"
                            inputFieldClassName="col-auto"
                            formGroupClassName="mb-0"
                            onChanged={onChangeEmployeeCareTeamOnlyAvailable}
                            disabled={isDisabled}
                            uitype="switch"
                            colorStyle="secondary"
                        />
                        </CustomCardWidget>
                    </div> */}
        </div>
      </div>
      {/* <RAFDropdownCC
                                            field={propertyOf<ServiceTransactionRow>(
                                                "ShowEmployeeCareTeam"
                                            )}
                                            label="Show"
                                            onChanged={onChangeEmployeeCareTeam}
                                            showLabel={false}
                                            disabled={isDisabled}
                                            componentCssClass="remove_color e-input-sm"
                                            inputFieldClassName="col-auto"
                                            showClearButton={false}
                                            formGroupClassName=""
                                        >
                                            <RAFChoiceOption
                                                label="Employee"
                                                value={CareEsioEntity.Employee.EntityName}
                                            />
                                            <RAFChoiceOption
                                                label="Care Team"
                                                value={CareEsioEntity.CareTeam.EntityName}
                                            />
                                        </RAFDropdownCC> */}
      <div className="mt-3">
        <Condition
          when={propertyOf<ServiceTransactionRow>(
            "ShowEmployeeCareTeamOnlyAvailable"
          )}
          is={true}
        >
          <div className="mb-3">
            <CustomCardWidget
              cardClassName="surface_state_warning_light stroke_warning"
              cardContentClassName="p-2-5"
              style={{ borderColor: `var(--stroke-warning)` }}
            >
              <RAFCheckBox
                field={propertyOf<ServiceTransactionRow>(
                  "ShowEmployeeCareTeamOnlyAvailable"
                )}
                label="Only available"
                labelClassName="col"
                inputFieldClassName="col-auto"
                formGroupClassName="mb-0"
                onChanged={onChangeEmployeeCareTeamOnlyAvailable}
                disabled={isDisabled}
                uitype="switch"
                colorStyle="secondary"
                labelClass="body_1 content_state_warning_dark medium"
              />
            </CustomCardWidget>
          </div>
          {getAvailableEmployeeCareTeamContent(isDisabled)}
        </Condition>
        <Condition
          when={propertyOf<ServiceTransactionRow>(
            "ShowEmployeeCareTeamOnlyAvailable"
          )}
          is={false}
        >
          <div className="mb-3">
            <CustomCardWidget
              cardClassName="surface_state_warning_light"
              cardContentClassName="p-2-5"
            >
              <RAFCheckBox
                field={propertyOf<ServiceTransactionRow>(
                  "ShowEmployeeCareTeamOnlyAvailable"
                )}
                label="Only available"
                labelClassName="col"
                inputFieldClassName="col-auto"
                formGroupClassName="mb-0"
                onChanged={onChangeEmployeeCareTeamOnlyAvailable}
                disabled={isDisabled}
                uitype="switch"
                colorStyle="secondary"
                labelClass="body_1 content_neutral_dark medium"
              />
            </CustomCardWidget>
          </div>
          {getEmployeeCareTeamContent(isDisabled)}
        </Condition>
        {/* </CustomCardWidget> */}
      </div>
    </div>
  );
}

export default React.memo(ServiceTransactionSelectAssignee);
