import { DialogUtility } from "@syncfusion/ej2-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import { RRule } from "rrule";
import { msalInstance } from "../../../..";
import RAFForm, {
  ConditionNotNullOrWhiteSpace,
  WhenFieldChanges,
} from "../../../../RAFComponents/Inputs/RAFForm";
import RAFFrequencyRRuleInput from "../../../../RAFComponents/Inputs/RAFFrequencyRRuleInput";
import RAFLookUpMUI from "../../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFStartDateEndDatePicker from "../../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import RAFTextArea from "../../../../RAFComponents/Inputs/RAFTextArea";
import RAFTextBox from "../../../../RAFComponents/Inputs/RAFTextBox";
import {
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecordRemoveFocus,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import { getRuleByWeekday, getRuleFrequency } from "../../../../RAFComponents/helpers/RRuleInputHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  joinStringArray,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { RAFUIType } from "../../../../RAFComponents/models/Common/RAFDataType";
import { LookUpGroupRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import { ConvertSchedulerToCronExpression } from "../../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../../constants/Common/Constants";
import {
  RAFEntityName,
  RAFModuleName,
} from "../../../../constants/Common/EntityConstants";
import { initUserAndTeam } from "../../../../helpers/ACutils";
import {
  IsUserExistInCareTeam,
  addUserToCareTeam,
} from "../../../CareESIO/CareTeam/CareTeamHelper";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import {
  RAFServiceTransactionType,
  ServiceTransactionStates,
  getServiceTransactionDisplayNameFromStorage,
  getServiceTransactionTitleHourMinuteAMPMByDate,
  isServiceTransactionValidStartAndEndDateRange,
} from "../ServiceTransactionHelper";
import { saveServiceTransactionTemplate } from "./ServiceTransactionTemplateHelper";
import { ServiceTransactionTemplateRow } from "./ServiceTransactionTemplateRow";

interface IProps {
  isActive: boolean;

  relatedTo?: string;
  relatedToUID?: string;
  relatedToType?: string;

  assigneeUID?: string;
  assignee?: string;

  showRelatedToLookUp?: boolean;

  selectedServiceTransactionRow?: ServiceTransactionTemplateRow;

  setDefaultAssigneeValue?: boolean;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  serviceTransactionRow: ServiceTransactionTemplateRow;

  usersAndTeams: LookUpGroupRow[];
}

function ManageServiceTransactionTemplate({
  setDefaultAssigneeValue = true,
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const serviceTransactionDisplayName =
    getServiceTransactionDisplayNameFromStorage();
  const outerDivId = `raf_template_dlg_Outer_Div_${RAFModuleName.ServiceTransaction}`;
  let rafFormRef: FormRenderProps | null;
  let careTeamMemberAlertDialog: any;
  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: false,
      serviceTransactionRow: null,

      usersAndTeams: null,
    }
  );

  useEffect(() => {
    refesh();
  }, []);

  const refesh = async () => {
    const { isActive } = props;
    if (isActive) {
      setState({ isLoading: true });
      let serviceTransactionRow = new ServiceTransactionTemplateRow();
      if (
        isNotNullAndUndefined(props.selectedServiceTransactionRow) &&
        isNotNullAndUndefined(props.selectedServiceTransactionRow.UID)
      ) {
        serviceTransactionRow = props.selectedServiceTransactionRow;
      } else {
        serviceTransactionRow.Type = RAFServiceTransactionType.Shift;
        serviceTransactionRow.Status = RAFShiftActionStatus.Scheduled;
        serviceTransactionRow.State = ServiceTransactionStates.Draft;
        const rule = new RRule({
          freq: RRule.DAILY,
          wkst: RRule.MO,
          byweekday: null,
          interval: 1,
        });

        const cronExpression = ConvertSchedulerToCronExpression(
          rule.toString()
        );
        const ruleString = rule.toString();
        serviceTransactionRow.CronExpression = cronExpression;
        serviceTransactionRow.RRuleExpression = ruleString;

        if (setDefaultAssigneeValue) {
          serviceTransactionRow.AssigneeUID = msalInstance.currentUserId;
          serviceTransactionRow.Assignee = msalInstance.currentUserName;
        } else {
          serviceTransactionRow.AssigneeUID = props.assigneeUID;
          serviceTransactionRow.Assignee = props.assignee;
        }

        if (IsNotNullOrWhiteSpace(props.relatedTo)) {
          serviceTransactionRow.RelatedTo = props.relatedTo;
        }
        if (IsNotNullOrWhiteSpace(props.relatedToUID)) {
          serviceTransactionRow.RelatedToUID = props.relatedToUID;
        }
        if (IsNotNullOrWhiteSpace(props.relatedToType)) {
          serviceTransactionRow.RelatedToType = props.relatedToType;
        }
      }

      const [usersAndTeams] = await Promise.all([initUserAndTeam()]);

      setState({ serviceTransactionRow, isLoading: false, usersAndTeams });
    }
  };

  //submit form start
  const onEventFormSubmit = async (value: ServiceTransactionTemplateRow) => {
    const serviceTransactionTemplateRow = getFormValue(
      rafFormRef
    ) as ServiceTransactionTemplateRow;
    if (isNotNullAndUndefined(rafFormRef) && rafFormRef.invalid === true) {
      showWarningToast("Please provide all the required fields");
      return;
    }
    const frequency = getRuleFrequency(value.RRuleExpression);
    if (frequency === RRule.WEEKLY) {
      const ruleByWeekday = getRuleByWeekday(value.RRuleExpression);
      if (!isNotEmptyArray(ruleByWeekday)) {
        showWarningToast("Please select at least one day");
        return;
      }
    }

    if (
      isNotNullAndUndefined(serviceTransactionTemplateRow.StartDate) &&
      isNotNullAndUndefined(serviceTransactionTemplateRow.EndDate)
    ) {
      const isValidDateRange = isServiceTransactionValidStartAndEndDateRange(
        serviceTransactionTemplateRow.StartDate,
        serviceTransactionTemplateRow.EndDate,
        false
      );
      if (!isValidDateRange) {
        return;
      }
    }

    let progressDiv = showProgress(`#${outerDivId}`);
    if (
      isNotNullAndUndefined(serviceTransactionTemplateRow.AssigneeUID) &&
      isNotNullAndUndefined(serviceTransactionTemplateRow.RelatedToUID)
    ) {
      const isUserExist = await IsUserExistInCareTeam(
        serviceTransactionTemplateRow.RelatedToUID,
        serviceTransactionTemplateRow.AssigneeUID
      );

      if (isUserExist) {
        hideProgress(progressDiv);
        saveServiceTransactionTemplateForm();
      } else {
        hideProgress(progressDiv);
        careTeamMemberAlertClicked();
      }
    } else {
      hideProgress(progressDiv);
      saveServiceTransactionTemplateForm();
    }
  };

  const saveServiceTransactionTemplateForm = () => {
    const serviceTransactionTemplateRow = getFormValue(
      rafFormRef
    ) as ServiceTransactionTemplateRow;
    let progressDiv = showProgress(`#${outerDivId}`);
    if (IsNullOrWhiteSpace(serviceTransactionTemplateRow.AssigneeUID)) {
      serviceTransactionTemplateRow.Status = RAFShiftActionStatus.Unassigned;
    } else if (
      state.serviceTransactionRow.Status === RAFShiftActionStatus.Unassigned &&
      !IsNullOrWhiteSpace(serviceTransactionTemplateRow.AssigneeUID)
    ) {
      serviceTransactionTemplateRow.Status = RAFShiftActionStatus.Scheduled;
    }

    saveServiceTransactionTemplate(serviceTransactionTemplateRow).then(
      async (serviceTransactionResponse) => {
        if (
          isNotNullAndUndefined(serviceTransactionResponse) &&
          isNotNullAndUndefined(serviceTransactionResponse.UID)
        ) {
          hideProgress(progressDiv);
          if (props.onSave) {
            props.onSave(
              serviceTransactionResponse.UID,
              serviceTransactionResponse.Title
            );
          }
        } else {
          hideProgress(progressDiv);
          showWarningToast("Sorry something went wrong !");
        }
      }
    );
  };

  const careTeamMemberAlertClicked = () => {
    careTeamMemberAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content:
        "The selected user isn't part of the care team at the moment. Would you like to include them? If not, please ensure you select a member from the existing care team.",
      okButton: { text: "Yes", click: AddCareTeamMember.bind(this) },
      title: "Alert!",
      position: { X: "center", Y: "center" },
      cssClass: "raf-teammember_alert_dialog alert-dialog",
    });
  };

  const AddCareTeamMember = async () => {
    const serviceTransactionTemplateRow = getFormValue(
      rafFormRef
    ) as ServiceTransactionTemplateRow;

    let progressDiv = showProgress(".raf-teammember_alert_dialog.e-dialog");
    const responseCareTeamMember = await addUserToCareTeam(
      serviceTransactionTemplateRow.RelatedTo,
      serviceTransactionTemplateRow.RelatedToUID,
      serviceTransactionTemplateRow.AssigneeUID
    );
    hideProgress(progressDiv);
    careTeamMemberAlertDialog.hide();
    if (responseCareTeamMember.response) {
      saveServiceTransactionTemplateForm();
    } else {
      showWarningToast(responseCareTeamMember.warningMessage);
    }
  };
  //submit form end

  const onSelectRelatedTo = async (label, value) => {
    if (isNotNullAndUndefined(value) && isNotNullAndUndefined(label)) {
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionTemplateRow>("RelatedToType"),
        CareEsioEntity.CareRecipient.EntityName
      );
    } else {
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionTemplateRow>("RelatedToType"),
        null
      );
    }
  };

  const getServiceContractItemLookUp = () => {
    return (
      <Field name={propertyOf<ServiceTransactionTemplateRow>("RelatedToUID")}>
        {({ input }) => {
          let customFilter: RAFCustomFilter = {};
          customFilter.Condition = "and";
          customFilter.Rules = [];
          if (isNotNullAndUndefined(input.value)) {
            let filter: RAFCustomFilter = {};
            let filterVal: string[] = [];
            filterVal.push(input.value);
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterVal;
            filter.Field = "CareRecipientUID";
            customFilter.Rules.push(filter);
          }

          let parentFilter: RAFCustomFilter = {};
          parentFilter.Operator = RAFCustomOperator.IsNull;
          parentFilter.Field = "ParentUID";
          customFilter.Rules.push(parentFilter);
          return (
            <RAFLookUpMUI<ServiceTransactionTemplateRow>
              field="ServiceContractItem"
              label="Service Contract Item"
              placeholder="Select Service Contract Item"
              url="ServiceContractItem/Lookup"
              moduleName={CareEsioEntity.CareServiceContractItem.EntityName}
              customFilter={customFilter}
              showLabel
              closeToolTip={false}
              showFullList={false}
              onChanged={onChangeServiceContractItem}
            />
          );
        }}
      </Field>
    );
  };

  const getFrequencyContent = () => {
    return (
      <RAFFrequencyRRuleInput
        field={propertyOf<ServiceTransactionTemplateRow>("RRuleExpression")}
        rRuleExpressionPropertyName={propertyOf<ServiceTransactionTemplateRow>(
          "RRuleExpression"
        )}
        setStartEndDate={false}
        required
      />
    );
  };

  const onChangeServiceContractItem = (value: string) => {
    const startDate = getFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionTemplateRow>("StartDate")
    );
    const endDate = getFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionTemplateRow>("EndDate")
    );
    updateTitle(startDate, endDate, value);
  };

  const onChangeStartDateEndDate = () => {
    setTimeout(() => {
      const startDate = getFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionTemplateRow>("StartDate")
      );
      const endDate = getFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionTemplateRow>("EndDate")
      );
      const dateTimetitle = getServiceTransactionTitleHourMinuteAMPMByDate(
        startDate,
        endDate
      );
      const serviceContractItem = getFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionTemplateRow>("ServiceContractItem")
      );
      const title = joinStringArray([dateTimetitle, serviceContractItem], " ");
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionTemplateRow>("Title"),
        title
      );
      updateTitle(startDate, endDate, serviceContractItem);
    }, 500);
  };

  const updateTitle = (
    startDate: Date,
    endDate: Date,
    serviceContractItem: string
  ) => {
    const dateTimetitle = getServiceTransactionTitleHourMinuteAMPMByDate(
      startDate,
      endDate
    );
    const title = joinStringArray([dateTimetitle, serviceContractItem], " ");
    setFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionTemplateRow>("Title"),
      title
    );
  };

  const getFormContent = () => {
    return (
      <div>
        <RAFEntityProvider moduleName={RAFModuleName.ServiceTransaction}>
          <RAFAttributeRelatedListProvider
            moduleName={RAFModuleName.ServiceTransaction}
            progressDivId={`#${outerDivId}`}
          >
            <RAFForm
              initialValues={state.serviceTransactionRow}
              formRef={(g) => {
                return (rafFormRef = g);
              }}
              layout={RAFLayout.TwoColumnLayout}
              submitOnEnterKey={false}
              onSubmit={onEventFormSubmit}
            >
              <div className="row gy-4 g-0 flex-column text-align-center form-group-margin-0">
                {props.showRelatedToLookUp && (
                  <>
                    <RAFLookUpMUI<ServiceTransactionTemplateRow>
                      field="RelatedTo"
                      label="Client"
                      placeholder="Select client"
                      url="CareRecipient/Lookup"
                      lookupGridURL="CareRecipient/List"
                      moduleName={CareEsioEntity.CareRecipient.EntityName}
                      showLabel
                      onChanged={(label, value) =>
                        onSelectRelatedTo(label, value)
                      }
                      closeToolTip={false}
                      showFullList={false}
                    />
                    <WhenFieldChanges
                      field={propertyOf<ServiceTransactionTemplateRow>(
                        "RelatedTo"
                      )}
                      set={"ServiceContractItem"}
                      to={null}
                    />
                    <WhenFieldChanges
                      field={propertyOf<ServiceTransactionTemplateRow>(
                        "RelatedTo"
                      )}
                      set={"ServiceContractItemUID"}
                      to={null}
                    />
                    <WhenFieldChanges
                      field={propertyOf<ServiceTransactionTemplateRow>(
                        "RelatedTo"
                      )}
                      set={"SupportTasks"}
                      to={null}
                    />
                    <WhenFieldChanges
                      field={propertyOf<ServiceTransactionTemplateRow>(
                        "RelatedTo"
                      )}
                      set={"PlannedActivities"}
                      to={null}
                    />
                  </>
                )}
                <div className="col-md-12">{getFrequencyContent()}</div>
                <div className="col-md-12">
                  <RAFStartDateEndDatePicker
                    field={propertyOf<ServiceTransactionTemplateRow>(
                      "StartDate"
                    )}
                    endDateField={propertyOf<ServiceTransactionTemplateRow>(
                      "EndDate"
                    )}
                    label="From Date"
                    endDateLabel={"To Date"}
                    formGroupClassName="mb-0"
                    interval={15}
                    startEndDateDurationHrs={26}
                    updateEndDateOnStartDateChange={false}
                    hideDuration
                    roundOff
                    onChangeStartDateEndDate={onChangeStartDateEndDate}
                    startDateUItype={RAFUIType.DateTime}
                    endDateUItype={RAFUIType.DateTime}
                  />
                </div>
                <ConditionNotNullOrWhiteSpace
                  when={propertyOf<ServiceTransactionTemplateRow>(
                    "RelatedToUID"
                  )}
                >
                  <>
                    {getServiceContractItemLookUp()}
                    {/* {getPlannerActivityContent()}
                                        {getCarePlanContent()} */}
                  </>
                </ConditionNotNullOrWhiteSpace>
                <div className="col-md-12">
                  <RAFTextArea<ServiceTransactionTemplateRow>
                    field="Description"
                    showLabel
                    label="Description"
                    placeholder="Description"
                    rows={3}
                  />
                </div>
                <div className="col-md-12">
                  <RAFLookUpMUI<ServiceTransactionTemplateRow>
                    field={propertyOf<ServiceTransactionTemplateRow>(
                      "Assignee"
                    )}
                    label="Assigned To"
                    url={"User/EmployeeLookup"}
                    placeholder="Select assignee"
                    showLabel
                  />
                </div>
                <div className="col-md-12">
                  <RAFTextBox<ServiceTransactionTemplateRow>
                    field={propertyOf<ServiceTransactionTemplateRow>("Title")}
                    showLabel
                    label="Template Title"
                    placeholder="Template Title"
                    required
                  />
                </div>
              </div>
            </RAFForm>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  };

  const DeleteServiceTransactionClicked = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: DeleteServiceTransaction.bind(this) },
      showCloseIcon: false,
      title: `Delete ${serviceTransactionDisplayName} Template`,
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
    });
  };

  const DeleteServiceTransaction = () => {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    const { serviceTransactionRow } = state;
    DeleteRecordRemoveFocus(
      serviceTransactionRow.UID,
      RAFEntityName.ServiceTransaction
    ).then((response) => {
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(response)) {
        deleteDialog.hide();
        if (props.onSave) {
          props.onSave(null, null);
        }
      }
    });
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (isNotNullAndUndefined(state.serviceTransactionRow)) {
        return (
          <div
            className="h-100"
            key={RAFModuleName.ServiceTransaction}
            id={outerDivId}
          >
            <div className="e-dlg-content-outer">
              <div className="e-dlg-body-content">{getFormContent()}</div>
              <div className="e-dlg-footerContent">
                <div className="w-100">
                  <div className="row gx-2">
                    {isNotNullAndUndefined(
                      props.selectedServiceTransactionRow
                    ) &&
                      isNotNullAndUndefined(
                        props.selectedServiceTransactionRow.UID
                      ) && (
                        <div className="col-auto">
                          <RAFPermissionRender
                            permissionName={
                              ServiceTransactionPermissionConstants.ServiceTransactionManageShiftTemplate
                            }
                          >
                            <RAFButtonComponent
                              action={RAFButtonConstant.Delete}
                              onClick={DeleteServiceTransactionClicked}
                              className="e-danger e-outline"
                              iconBtn
                            />
                          </RAFPermissionRender>
                        </div>
                      )}
                    <div className="col-auto ms-auto">
                      <RAFPermissionRender
                        permissionName={
                          ServiceTransactionPermissionConstants.ServiceTransactionManageShiftTemplate
                        }
                      >
                        <RAFButtonComponent
                          type="button"
                          isPrimary
                          action={RAFButtonConstant.Save}
                          onClick={() => rafFormRef && rafFormRef.form.submit()}
                          idString="CreateContent"
                        />
                      </RAFPermissionRender>
                    </div>
                    <div className="col-auto d-none d-sm-block">
                      <RAFButtonComponent
                        type="button"
                        action={RAFButtonConstant.Cancel}
                        onClick={props.onClose}
                        idString="CreateContent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageServiceTransactionTemplate);
