import { DialogUtility } from "@syncfusion/ej2-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFDateTimePicker from "../../../../RAFComponents/Inputs/RAFDateTimePicker";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFLatitudeLongitude from "../../../../RAFComponents/Inputs/RAFLatitudeLongitude";
import RAFNumber from "../../../../RAFComponents/Inputs/RAFNumber";
import { getFormValue } from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showErrorToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  IsSuperAdmin,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, {
  hasPermission,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  getTimeDifferenceInMinutes,
  getUTCDateValue,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../../constants/Common/Constants";
import {
  SaveShift,
  ShiftActivityTitle,
  getShiftActivitiesByTaskId
} from "../../../CareESIO/CareShiftLog/CareShiftLogHelper";
import CreateContent from "../../../Common/Create/CreateContent";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import { getUserTenantSettings } from "../../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../../Common/TenantSettings/TenantSettingsRow";
import EditContent from "../../../Common/Update/EditContent";
import {
  checkHasPermissionToStartOrCompleteShift
} from "../ServiceTransactionHelper";
import { ServiceTransactionRow } from "../ServiceTransactionRow";

interface IProps {
  isActive: boolean;
  taskAction: "Clock In" | "Clock Out";
  selectedServiceTransactionRow: ServiceTransactionRow;
  shiftActivityObjectUID: string;

  minTimeValue: Date;
  maxTimeValue: Date;
  taskDateValue: Date;
  isEdit?: boolean;
}

interface IState {
  isActive: boolean;
  isLoading: boolean;
  initialActionFormValue: {
    Action: string;
    Message: string;
    Mentions: string[];
    TaskDate: Date;
  };
  shiftActivityInitialValue: any;
  tenantSettings: TenantSettingsRow;
}

function ManageShiftClockAction({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  let deleteDialog: any;

  const shiftActivityModuleName = CareEsioEntity.CareShiftActivity.EntityName;
  const completeShiftModuleName = CareEsioEntity.CareCompleteShift.EntityName;

  let rafShiftActivityForm: FormRenderProps | null;
  let rafShiftForm: FormRenderProps | null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: props.isActive,
      isLoading: true,
      initialActionFormValue: null,
      shiftActivityInitialValue: null,
      tenantSettings: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const { isActive, shiftActivityObjectUID, selectedServiceTransactionRow } =
      props;
    if (isActive) {
      setState({ isLoading: true });
      let moduleName;
      if (props.taskAction === "Clock In") {
        moduleName = CareEsioEntity.CareShiftActivity.EntityName;
      } else if (props.taskAction === "Clock Out") {
        moduleName = CareEsioEntity.CareCompleteShift.EntityName;
      }
      let shiftActivityInitialValue;
      if (isNotNullAndUndefined(shiftActivityObjectUID)) {
        shiftActivityInitialValue = await RetrieveRecord(
          shiftActivityObjectUID,
          moduleName
        );
      } else if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
        shiftActivityInitialValue = {
          care_recipientuid: selectedServiceTransactionRow.RelatedToUID,
          care_recipient: selectedServiceTransactionRow.RelatedTo,
          start_date: null, //
          end_date: null, //
          task: selectedServiceTransactionRow.Title,
          taskuid: selectedServiceTransactionRow.UID,
          //FormLibrary: "Shift Activity",
          //FormLibraryUID: "ef9692c1-2247-4a89-83b1-add3283e8a2d",
          status: RAFShiftActionStatus.Done,
          title:
            props.taskAction === "Clock Out"
              ? ShiftActivityTitle.CompletedShift
              : ShiftActivityTitle.StartedShift,
          description: null, //
        };
      }

      const initialActionFormValue = {
        Action: "No",
        Message: isNotNullAndUndefined(shiftActivityInitialValue)
          ? shiftActivityInitialValue.description
          : null,
        travel_distance: isNotNullAndUndefined(shiftActivityInitialValue)
          ? shiftActivityInitialValue.travel_distance
          : null,
        Mentions: [],
        TaskDate: isNotNullAndUndefined(props.taskDateValue)
          ? props.taskDateValue
          : new Date(),
      };

      const tenantSettings = await getUserTenantSettings();

      setState({
        initialActionFormValue,
        shiftActivityInitialValue,
        tenantSettings,
        isLoading: false,
        isActive,
      });
    } else {
      setState({ isActive });
    }
  };

  const onCloseBtnClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const onSubmitComment = async (value) => {
    const { selectedServiceTransactionRow } = props;
    if (
      isNotNullAndUndefined(rafShiftActivityForm) &&
      rafShiftActivityForm.invalid
    ) {
      showErrorToast("Please provide all the required fields");
      return;
    }

    if (
      isNotNullAndUndefined(value) &&
      isNotNullAndUndefined(selectedServiceTransactionRow)
    ) {
      if (props.taskAction === "Clock In") {
        if (isNotNullAndUndefined(selectedServiceTransactionRow.ActualEndDate)) {
          const actualStartDate: Date = new Date(value.TaskDate);
          const actualEndDate: Date = new Date(selectedServiceTransactionRow.ActualEndDate);
          //check if the actual start date is greater than the actual end date
          if (actualStartDate > actualEndDate) {
            showErrorToast("Start time cannot be greater than end time.");
            return;
          }
        }
      } if (props.taskAction === "Clock Out") {
        if (isNotNullAndUndefined(selectedServiceTransactionRow.ActualStartDate)) {
          const actualStartDate: Date = new Date(selectedServiceTransactionRow.ActualStartDate);
          const actualEndDate: Date = new Date(value.TaskDate);
          //check if the actual start date is greater than the actual end date
          if (actualStartDate > actualEndDate) {
            showErrorToast("End time cannot be greater than start time.");
            return;
          }
        }
      }

      let progressDiv = showProgress("#taskClockAction_div");
      const taskStatus =
        props.taskAction === "Clock In"
          ? RAFShiftActionStatus.InProgress
          : RAFShiftActionStatus.Completed;

      if (taskStatus === RAFShiftActionStatus.Completed) {
        const childTaskItems = await getShiftActivitiesByTaskId(
          selectedServiceTransactionRow.UID
        );
        const plannedTask = isNotEmptyArray(childTaskItems)
          ? childTaskItems.filter(
            (item: any) => item.Status == RAFShiftActionStatus.Planned
          )
          : [];

        if (isNotEmptyArray(plannedTask)) {
          showAlertContent();
          hideProgress(progressDiv);
          return;
        }
      }

      /* Commented by hari for shift activity
      if (IsNotNullOrWhiteSpace(value.Message)) {
        let activityRow: ActivityRow = new ActivityRow();
        activityRow.UserUID = msalInstance.currentUserId;
        activityRow.UserName = msalInstance.currentUserName;

        activityRow.RelatedToType = selectedServiceTransactionRow.RelatedToType;
        activityRow.Action = "added";
        activityRow.IsSystem = false;
        activityRow.RelatedToUID = isNotNullAndUndefined(
          selectedServiceTransactionRow.RelatedToUID
        )
          ? selectedServiceTransactionRow.RelatedToUID
          : "";
        activityRow.RelatedTo = isNotNullAndUndefined(
          selectedServiceTransactionRow.RelatedTo
        )
          ? selectedServiceTransactionRow.RelatedTo
          : "";

        activityRow.SecondaryRelatedTo = selectedServiceTransactionRow.Title;
        activityRow.SecondaryRelatedToType = RAFEntityName.Task;
        activityRow.SecondaryRelatedToUID = selectedServiceTransactionRow.UID;

        activityRow.Mentions = value.Mentions;
        activityRow.Message = value.Message;
        const response = await createUpdateActivityRow(activityRow);
        if (isNullOrUndefined(response)) {
          hideProgress(progressDiv);
          showWarningToast("Sorry something went wrong !");
          return;
        }
      }
      */

      let submitFormValue: ServiceTransactionRow = {};
      submitFormValue.UID = selectedServiceTransactionRow.UID;

      if (isNotNullAndUndefined(props.shiftActivityObjectUID)) {
        if (props.taskAction === "Clock In") {
          const actualStartDate = value.TaskDate;
          const actualEndDate =
            selectedServiceTransactionRow.ActualEndDate ?? null;
          submitFormValue["ActualStartDate"] = actualStartDate;
          if (
            isNotNullAndUndefined(actualStartDate) &&
            isNotNullAndUndefined(actualEndDate)
          ) {
            const actualDuration = getTimeDifferenceInMinutes(
              getUTCDateValue(actualStartDate),
              getUTCDateValue(actualEndDate)
            );
            submitFormValue["ActualDuration"] = actualDuration;
          }
        } else {
          const actualStartDate =
            selectedServiceTransactionRow.ActualStartDate ?? null;
          const actualEndDate = value.TaskDate;
          submitFormValue["ActualEndDate"] = actualEndDate;
          if (
            isNotNullAndUndefined(actualStartDate) &&
            isNotNullAndUndefined(actualEndDate)
          ) {
            const actualDuration = getTimeDifferenceInMinutes(
              getUTCDateValue(actualStartDate),
              getUTCDateValue(actualEndDate)
            );
            submitFormValue["ActualDuration"] = actualDuration;
          }
        }
      } else {
        const taskActionDate = value.TaskDate;
        const actualDuration =
          taskStatus === RAFShiftActionStatus.Completed
            ? getTimeDifferenceInMinutes(
              getUTCDateValue(selectedServiceTransactionRow.ActualStartDate),
              getUTCDateValue(value.TaskDate)
            )
            : null;

        submitFormValue.Status = taskStatus;
        if (
          taskStatus === RAFShiftActionStatus.Completed &&
          isNotNullAndUndefined(taskActionDate)
        ) {
          submitFormValue["ActualEndDate"] = taskActionDate;
          if (isNotNullAndUndefined(actualDuration)) {
            submitFormValue["ActualDuration"] = actualDuration;
          }
        }
        if (
          taskStatus === RAFShiftActionStatus.InProgress &&
          isNotNullAndUndefined(taskActionDate)
        ) {
          submitFormValue["ActualStartDate"] = taskActionDate;
        }
      }

      //shiftActivityInitialValue

      const shiftActivityFormValue = isNotNullAndUndefined(rafShiftActivityForm)
        ? getFormValue(rafShiftActivityForm)
        : state.shiftActivityInitialValue;

      submitFormValue.TravelDistance = isNotNullAndUndefined(
        shiftActivityFormValue
      )
        ? shiftActivityFormValue.travel_distance
        : null;
      //commented by hari to avoid the update of service transaction row
      //const updateTask = await updateServiceTransactionRow(submitFormValue);

      if (isNotNullAndUndefined(shiftActivityFormValue)) {
        shiftActivityFormValue.start_date = value.TaskDate;
        shiftActivityFormValue.end_date = value.TaskDate;
        shiftActivityFormValue.end_date = value.TaskDate;
        if (props.taskAction === 'Clock Out') {
          shiftActivityFormValue.travel_distance = value.travel_distance;
        }
        shiftActivityFormValue.description = IsNotNullOrWhiteSpace(
          value.Message
        )
          ? value.Message
          : null;

        shiftActivityFormValue.locationLatitude = value.LocationLatitude;
        shiftActivityFormValue.locationlongitude = value.LocationLongitude;
        if (isNullOrUndefined(props.shiftActivityObjectUID)) {
          shiftActivityFormValue.assignee =
            selectedServiceTransactionRow.Assignee ??
            msalInstance.currentUserName;
          shiftActivityFormValue.assigneeuid =
            selectedServiceTransactionRow.AssigneeUID ??
            msalInstance.currentUserId;
        }

        let saveRequestData = getSaveRequest(
          shiftActivityFormValue,
          props.shiftActivityObjectUID
        );
        saveRequestData.EntityName =
          props.taskAction === "Clock In"
            ? shiftActivityModuleName
            : completeShiftModuleName;
        let url;
        if (props.taskAction === "Clock In") {
          url = "DataList/StartShift";
        } else {
          url = "DataList/EndShift";
        }
        //commented by hari to avoid the update of service transaction row
        //const newActivity = await SaveShiftActivity(saveRequestData);
        const shiftActivity = await SaveShift(saveRequestData, url);
        if (isNotNullAndUndefined(shiftActivity)) {
          if (isNotNullAndUndefined(props.onSave)) {
            props.onSave(submitFormValue.UID, submitFormValue.Title);
          }
        } else {
          showWarningToast(
            "Apologies, we're unable to update the shift at the moment. Please try again later.!"
          );
        }
      }

      //commented by hari to avoid the update of service transaction row
      //hideProgress(progressDiv);
      // if (isNotNullAndUndefined(updateTask)) {
      //   if (props.onSave)
      //     props.onSave(updateTask.entityId, updateTask.objectName);
      // } else {
      //   showWarningToast("Sorry something went wrong !");
      // }
    } else {
      showWarningToast("Sorry something went wrong !");
    }
  };

  const showAlertContent = () => {
    deleteDialog = DialogUtility.alert({
      animationSettings: { effect: "Fade" },
      content:
        "One or more support planned activities must be completed before clocking out of this shift.",
      okButton: { text: "OK", click: alertOkButtonClick.bind(this) },
      title: "Alert",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      closeOnEscape: true,
      showCloseIcon: false,
    });
  };

  const alertOkButtonClick = () => {
    deleteDialog.hide();
    if (props.onClose) {
      props.onClose();
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      const { selectedServiceTransactionRow } = props;
      let hasPermissionToStartOrCompleteShift = {
        hasPermissionToEdit: true,
        warningMessage: "",
      };

      if (
        props.taskAction === "Clock In" ||
        (props.taskAction === "Clock Out" &&
          isNotNullAndUndefined(selectedServiceTransactionRow))
      ) {
        hasPermissionToStartOrCompleteShift =
          checkHasPermissionToStartOrCompleteShift(
            state.tenantSettings,
            props.taskAction === "Clock In"
              ? selectedServiceTransactionRow.StartDate
              : selectedServiceTransactionRow.EndDate,
            props.taskAction
          );
      }

      if (hasPermissionToStartOrCompleteShift.hasPermissionToEdit === true) {
        const assigneeUID =
          selectedServiceTransactionRow.AssigneeUID ??
          msalInstance.currentUserId;

        const hasPermissionToEdit =
          selectedServiceTransactionRow.AssigneeUID ===
            msalInstance.currentUserId
            ? true
            : hasPermission(
              permissionValue,
              ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsTeamShiftActions
            );

        if (hasPermissionToEdit) {
          const shiftStatus = selectedServiceTransactionRow.Status;
          const hasPermissionToEditClockIn =
            IsSuperAdmin() ? true : (props.taskAction === 'Clock In' && (shiftStatus === RAFShiftActionStatus.Scheduled || shiftStatus === RAFShiftActionStatus.Accepted || shiftStatus === RAFShiftActionStatus.InProgress)) ? true : false;

          return (
            <div className="h-100" id="taskClockAction_div">
              <div className="e-dlg-content-outer">
                <div className="e-dlg-body-content">
                  <RAFForm
                    formRef={(g) => (rafShiftForm = g)}
                    initialValues={state.initialActionFormValue}
                    submitOnEnterKey={false}
                    layout={RAFLayout.TwoColumnLayout}
                    onSubmit={onSubmitComment}
                  >
                    <>
                      <RAFDateTimePicker
                        field="TaskDate"
                        label={
                          props.taskAction === "Clock In"
                            ? "In time"
                            : "Out time"
                        }
                        showLabel
                        showClearButton={false}
                        roundOff={false}
                        allowKeyboardInteraction
                        disableDatePicker={!hasPermissionToEditClockIn}
                        // minDateValue={props.minTimeValue}
                        // maxDateValue={props.maxTimeValue}
                        disabled={!hasPermissionToEditClockIn}
                        required
                      />
                      <div className="row">
                        <div className="w-100" id="actionTextAreaDiv">
                          {/* <RAFTextArea<ActivityRow>
                                        field="Message"
                                        label="Add Comment"
                                        placeholder="Add notes, comments, or @ to mention a person"
                                        rows={5}
                                        onInputs={(e) => { }}
                                        onChanged={(e) => { }}
                                        useMentions
                                        mentionsField="Mentions"
                                    /> */}
                          {/* <RAFTextArea
                        field="Message"
                        showLabel
                        label="Description"
                        placeholder="Description"
                        // rows={2}
                        formGroupClassName="m-0"
                      /> */}
                          <RAFHtmlEditor
                            field="Message"
                            label="Description"
                            showLabel={true}
                            placeholder="Description"
                            rows={5}
                            fullHeight={false}
                            rowClassName="row g-0 gy-2"
                          />
                        </div>
                      </div>
                      <RAFPermissionRender
                        permissionName={
                          ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableLocation
                        }
                      >
                        <div className="row">
                          <div className="w-100">
                            <RAFLatitudeLongitude
                              label="Location"
                              field={"Location"}
                              latitudeField={"LocationLatitude"}
                              longitudeField={"LocationLongitude"}
                            />
                          </div>
                        </div>
                      </RAFPermissionRender>
                      {/* {(props.taskAction === "Clock Out" &&
                        selectedServiceTransactionRow.EnableTravel === true) && ( */}
                      {(props.taskAction === "Clock Out") && (
                        <div className="row">
                          <div className="w-100">
                            <RAFNumber
                              field={"travel_distance"}
                              label="Travel KM"
                              placeholder={"travel km"}
                              decimalsPoints={2}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  </RAFForm>
                  {props.taskAction === "Clock Out" && (
                    <div className="col-md-12 mt-2">
                      <RAFEntityProvider moduleName={completeShiftModuleName}>
                        <RAFAttributeRelatedListProvider
                          moduleName={completeShiftModuleName}
                        >
                          <RAFForm
                            formRef={(g) => (rafShiftActivityForm = g)}
                            layout={RAFLayout.OneColumnLayout}
                            initialValues={state.shiftActivityInitialValue}
                          >
                            {isNotNullAndUndefined(
                              props.shiftActivityObjectUID
                            ) ? (
                              <EditContent
                                moduleName={completeShiftModuleName}
                                progressDivId={`#add_shift_activity_dlg_outerDiv`}
                              />
                            ) : (
                              <CreateContent
                                moduleName={completeShiftModuleName}
                                progressDivId={`#add_shift_activity_dlg_outerDiv`}
                              />
                            )}
                          </RAFForm>
                        </RAFAttributeRelatedListProvider>
                      </RAFEntityProvider>
                    </div>
                  )}
                </div>
                <div className="e-dlg-footerContent">
                  <ButtonComponent
                    type="button"
                    onClick={() => rafShiftForm && rafShiftForm.form.submit()}
                    className="form-custom-button"
                    isPrimary
                  >
                    {props.isEdit ? 'Save' : props.taskAction === "Clock In" ? 'Start Shift' : 'End Shift'}
                  </ButtonComponent>
                  <ButtonComponent
                    type="button"
                    cssClass="form-custom-button"
                    onClick={() => onCloseBtnClick()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="e-dlg-content-outer">
              <div className="p-3 py-2 columnChooserPanel customScrollBar">
                <RAFDeletedRecordState
                  title={`Apologies, this shift is assigned to ${selectedServiceTransactionRow.Assignee
                    }. You cannot ${isNotNullAndUndefined(props.shiftActivityObjectUID)
                      ? "edit"
                      : "start"
                    } a shift that is not assigned to you.`}
                />
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="e-dlg-content-outer">
            <div className="p-3 py-2 columnChooserPanel customScrollBar">
              <RAFDeletedRecordState
                title={`${hasPermissionToStartOrCompleteShift.warningMessage}`}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageShiftClockAction);
